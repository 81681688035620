import { defineMessages, FormattedMessage, useIntl } from "react-intl";

import {
  mortgageDashboardDeserializer,
  useSidebarFilters,
  mortgageDashboardSerializer,
} from "common/mortgage/transactions/dashboard/filter";
import {
  Sidebar,
  SidebarSectionHeader,
  SidebarTabButton,
  SidebarTabButtonSublabel,
  SidebarTabLink,
} from "common/sidebar";
import { useDashboardSegmentTrack, useShowEasylinks } from "common/dashboard/util";
import {
  Feature,
  type OrganizationTransactionDetailedStatus,
  type OrganizationTransactionLabels,
} from "graphql_globals";
import {
  TransactionSectionNamespace,
  TransactionSubsectionNamespace,
} from "common/dashboard/filter_dropdown/common";
import { SEGMENT_EVENTS } from "constants/analytics";
import Icon from "common/core/icon";
import { useProofDefend, useProofDefendUpsell } from "util/feature_detection";
import { TEMPLATES_DASHBOARD_PATH, EASYLINK_DASHBOARD_PATH } from "util/routes";
import { usePermissions } from "common/core/current_user_role";
import { Badge } from "common/core/badge";
import { useFeatureFlag } from "common/feature_gating";

import Styles from "./transactions_sidebar.module.scss";
import type { SubTab } from "./subtab";
import type { LenderTransactions_node_Organization as Organization } from "./transactions.query.graphql";

const MESSAGES = defineMessages({
  tools: {
    id: "b2a363d4-1298-4678-8607-8a38eabaf8a5",
    defaultMessage: "Tools",
  },
});

type Props = {
  organization: Organization | null;
  inProgressStatuses: OrganizationTransactionDetailedStatus[];
  showDuplicateUI: boolean;
  readyToSendProgresses: OrganizationTransactionLabels[];
  draftStatuses: OrganizationTransactionDetailedStatus[];
  subTab: SubTab | null;
  section: TransactionSectionNamespace;
  transactionsActive: boolean;
};

export default function TransactionsSidebar({
  organization,
  inProgressStatuses,
  showDuplicateUI,
  readyToSendProgresses,
  draftStatuses,
  subTab,
  section,
  transactionsActive,
}: Props) {
  const realEstateEasylinkEnabled = useFeatureFlag("real-estate-easylink");
  const showEasylinks =
    useShowEasylinks(organization?.featureList || []) && realEstateEasylinkEnabled;
  const intl = useIntl();
  const { hasPermissionFor } = usePermissions();
  const proofDefendUpsell = useProofDefendUpsell(organization);
  const proofDefend = useProofDefend(organization);
  const dashboardSegmentTrack = useDashboardSegmentTrack();

  const {
    deserializedArgs,
    selectAllTransactionTab,
    selectByDetailedStatusesSubTab,
    selectByOrderProgressesSubTab,
    selectOpenOrderTab,
    selectIdentityIssueSubTab,
    selectIdentityRiskSubTab,
    selectCreatedByMeSubTab,
    selectTabByName,
  } = useSidebarFilters(mortgageDashboardDeserializer, mortgageDashboardSerializer);
  const { subSection } = deserializedArgs;
  const allTransactionsCount = organization?.allTransactionsCount.totalCount;
  const createdByMeTransactionsCount = organization?.createdByMeTransactionsCount.totalCount;
  const draftTransactionsCount = organization?.draftTransactionsCount.totalCount;
  const inProgressTransactionsCount = organization?.inProgressTransactionsCount.totalCount;
  const identityIssuesTransactionsCount = organization?.identityIssuesTransactionsCount.totalCount;
  const openOrderTransactionsCount = organization?.openOrderTransactionsCount.totalCount;
  const readyToSendTransactionsCount = organization?.readyToSendTransactionsCount.totalCount;
  const duplicateTransactionsCount = organization?.duplicateTransactionsCount.totalCount;
  const identityRiskTransactionsCount = organization?.identityRiskTransactionsCount.totalCount;
  const archivedTransactionsCount = organization?.archivedTransactionsCount.totalCount;
  const showTemplates =
    organization?.featureList.includes(Feature.DOCUMENT_TEMPLATES) &&
    hasPermissionFor("manageLenderTemplates");
  const isAllTransactionsSelected = section === TransactionSectionNamespace.ALL && !subTab;

  return (
    <Sidebar
      title={
        <FormattedMessage id="b0eba33a-46cb-4f7f-b8c1-596434d5cd6e" defaultMessage="My closings" />
      }
    >
      <SidebarTabButton
        active={transactionsActive && isAllTransactionsSelected}
        onClick={() => {
          if (!isAllTransactionsSelected || subSection !== TransactionSubsectionNamespace.DEFAULT) {
            dashboardSegmentTrack(SEGMENT_EVENTS.DETAIL_STATUS_FILTER_SELECTED, {
              value: "SIDEBAR_ALL_TRANSACTIONS",
            });
            selectAllTransactionTab();
          }
        }}
        automationId="transaction-filter-all"
        isHeader
      >
        <FormattedMessage
          id="88e7cff5-859e-45b7-8f65-8d23ad8a9529"
          defaultMessage="All transactions"
        />
        <SidebarTabButtonSublabel>{allTransactionsCount}</SidebarTabButtonSublabel>
      </SidebarTabButton>
      <SidebarTabButton
        active={transactionsActive && subTab === TransactionSubsectionNamespace.CREATED_BY_ME}
        onClick={() => {
          if (subTab !== TransactionSubsectionNamespace.CREATED_BY_ME) {
            dashboardSegmentTrack(SEGMENT_EVENTS.DETAIL_STATUS_FILTER_SELECTED, {
              value: "SIDEBAR_CREATED_BY_ME",
            });
            selectCreatedByMeSubTab();
          }
        }}
        automationId="transaction-filter-created-by-me"
        isSubHeader
      >
        <FormattedMessage
          id="986ca999-474d-448e-9f2e-7f4dbe774176"
          defaultMessage="Created by me"
        />
        <SidebarTabButtonSublabel>{createdByMeTransactionsCount}</SidebarTabButtonSublabel>
      </SidebarTabButton>
      <SidebarTabButton
        active={transactionsActive && subTab === "draft"}
        onClick={() => {
          if (subTab !== "draft") {
            dashboardSegmentTrack(SEGMENT_EVENTS.DETAIL_STATUS_FILTER_SELECTED, {
              value: "SIDEBAR_ALL_DRAFTS",
            });
            selectByDetailedStatusesSubTab(draftStatuses);
          }
        }}
        automationId="transaction-filter-drafts"
        isSubHeader
      >
        <FormattedMessage id="29f0c564-57eb-47ca-9d6c-3e5739ab32bb" defaultMessage="Drafts" />
        <SidebarTabButtonSublabel>{draftTransactionsCount}</SidebarTabButtonSublabel>
      </SidebarTabButton>
      <SidebarTabButton
        active={transactionsActive && subTab === "in_progress"}
        onClick={() => {
          if (subTab !== "in_progress") {
            dashboardSegmentTrack(SEGMENT_EVENTS.DETAIL_STATUS_FILTER_SELECTED, {
              value: "SIDEBAR_ALL_IN_PROGRESS",
            });
            selectByDetailedStatusesSubTab(inProgressStatuses);
          }
        }}
        automationId="transaction-filter-in-progress"
        isSubHeader
      >
        <FormattedMessage id="3c529273-e545-4b2e-b617-2c67de4120df" defaultMessage="In progress" />
        <SidebarTabButtonSublabel>{inProgressTransactionsCount}</SidebarTabButtonSublabel>
      </SidebarTabButton>
      <SidebarTabButton
        active={transactionsActive && subTab === TransactionSubsectionNamespace.KBA_ISSUES}
        onClick={() => {
          if (subTab !== TransactionSubsectionNamespace.KBA_ISSUES) {
            dashboardSegmentTrack(SEGMENT_EVENTS.DETAIL_STATUS_FILTER_SELECTED, {
              value: "SIDEBAR_ALL_IN_PROGRESS",
            });
            selectIdentityIssueSubTab();
          }
        }}
        isSubHeader
        automationId="kba-issues-subtab"
      >
        {Boolean(identityIssuesTransactionsCount) && (
          <Icon className={Styles.warningIcon} name="warning" />
        )}
        <FormattedMessage id="24bef222-1827-41fe-b9f0-fddd1c8fe8e7" defaultMessage="KBA issues" />
        <SidebarTabButtonSublabel>{identityIssuesTransactionsCount}</SidebarTabButtonSublabel>
      </SidebarTabButton>
      {(proofDefend || proofDefendUpsell) && (
        <SidebarTabButton
          active={transactionsActive && subTab === TransactionSubsectionNamespace.IDENTITY_RISK}
          onClick={() => {
            if (subTab === TransactionSubsectionNamespace.IDENTITY_RISK) {
              return;
            }
            if (proofDefend || proofDefendUpsell) {
              dashboardSegmentTrack(SEGMENT_EVENTS.DETAIL_STATUS_FILTER_SELECTED, {
                value: "SIDEBAR_IDENTITY_RISK",
              });
              selectIdentityRiskSubTab();
            }
          }}
          isSubHeader
          automationId="identity-risk-subtab"
        >
          <FormattedMessage
            id="19e54317-3527-4315-9747-c69c414734b4"
            defaultMessage="Identity risk"
          />
          {proofDefendUpsell ? (
            <SidebarTabButtonSublabel>
              <Badge kind="new">
                <FormattedMessage
                  id="7df9cfb4-4974-46dd-9ab5-744bd87a9d6a"
                  defaultMessage="Upgrade"
                />
              </Badge>
            </SidebarTabButtonSublabel>
          ) : (
            <SidebarTabButtonSublabel>{identityRiskTransactionsCount}</SidebarTabButtonSublabel>
          )}
        </SidebarTabButton>
      )}
      {(organization?.placeAnOrderEnabled || organization?.perTransactionPlaceAnOrderEnabled) && (
        <>
          <SidebarTabButton
            active={
              transactionsActive && section === TransactionSectionNamespace.OPEN_ORDER && !subTab
            }
            onClick={() => {
              if (!(section === TransactionSectionNamespace.OPEN_ORDER && !subTab)) {
                dashboardSegmentTrack(SEGMENT_EVENTS.DETAIL_STATUS_FILTER_SELECTED, {
                  value: "OPEN_ORDER_SECTION",
                });
                selectOpenOrderTab();
              }
            }}
            isHeader
          >
            <FormattedMessage
              id="9037e058-3c54-4bb0-bcbf-bd9a9893a985"
              defaultMessage="Open orders"
            />
            <SidebarTabButtonSublabel>{openOrderTransactionsCount}</SidebarTabButtonSublabel>
          </SidebarTabButton>
          {Boolean(readyToSendTransactionsCount) && !hasPermissionFor("manageOpenOrders") && (
            <SidebarTabButton
              active={transactionsActive && subTab === "ready_to_send"}
              onClick={() => {
                if (subTab !== "ready_to_send") {
                  dashboardSegmentTrack(SEGMENT_EVENTS.DETAIL_STATUS_FILTER_SELECTED, {
                    value: "READY_TO_SEND_SECTION",
                  });
                  selectByOrderProgressesSubTab(readyToSendProgresses);
                }
              }}
              isSubHeader
            >
              <FormattedMessage
                id="7cb2d415-893f-412d-8d13-a27846b36e29"
                defaultMessage="Ready to send"
              />
              <SidebarTabButtonSublabel pill={{ color: "orange" }}>
                {readyToSendTransactionsCount}
              </SidebarTabButtonSublabel>
            </SidebarTabButton>
          )}
        </>
      )}
      <SidebarTabButton
        active={transactionsActive && section === TransactionSectionNamespace.ARCHIVED}
        onClick={() => {
          selectTabByName(TransactionSectionNamespace.ARCHIVED);
        }}
        automationId="transaction-filter-archived"
        isHeader
      >
        <FormattedMessage id="2c1a441a-0aa6-49ed-9dcc-efd63edac9ac" defaultMessage="Archived" />
        <SidebarTabButtonSublabel>{archivedTransactionsCount}</SidebarTabButtonSublabel>
      </SidebarTabButton>
      {showDuplicateUI && (
        <SidebarTabButton
          active={transactionsActive && section === TransactionSectionNamespace.DUPLICATED}
          onClick={() => {
            if (section !== TransactionSectionNamespace.DUPLICATED) {
              dashboardSegmentTrack(SEGMENT_EVENTS.DETAIL_STATUS_FILTER_SELECTED, {
                value: "SIDEBAR_ALL_DUPLICATES",
              });
              selectTabByName(TransactionSectionNamespace.DUPLICATED);
            }
          }}
          automationId="transaction-filter-duplicated"
          isHeader
        >
          <FormattedMessage id="d57f2e8e-f30e-497c-a5b0-259890843d00" defaultMessage="Duplicated" />
          <SidebarTabButtonSublabel>{duplicateTransactionsCount}</SidebarTabButtonSublabel>
        </SidebarTabButton>
      )}
      {(showEasylinks || showTemplates) && (
        <>
          <SidebarSectionHeader title={intl.formatMessage(MESSAGES.tools)} />
          {showEasylinks && (
            <SidebarTabLink to={EASYLINK_DASHBOARD_PATH}>
              <div>
                <FormattedMessage
                  id="3a310ca2-0cdd-48ce-8c42-9670937373b5"
                  defaultMessage="EasyLinks"
                />
              </div>
              <SidebarTabButtonSublabel>
                {organization?.easylinks.totalCount}
              </SidebarTabButtonSublabel>
            </SidebarTabLink>
          )}
          {showTemplates && (
            <SidebarTabLink to={TEMPLATES_DASHBOARD_PATH}>
              <FormattedMessage
                id="fffce34a-8774-4ef1-8d5d-cb10ff06e77b"
                defaultMessage="Document templates"
              />
              <SidebarTabButtonSublabel>
                {organization?.templates.totalCount}
              </SidebarTabButtonSublabel>
            </SidebarTabLink>
          )}
        </>
      )}
    </Sidebar>
  );
}
